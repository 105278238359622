package ca.sebleclerc.localization.strings

import ca.sebleclerc.localization.strings.ui.AboutScreenStrings
import ca.sebleclerc.localization.strings.ui.AdminPanelStrings
import ca.sebleclerc.localization.strings.ui.BlogStrings
import ca.sebleclerc.localization.strings.ui.DatabaseInfoStrings
import ca.sebleclerc.localization.strings.ui.EnglishAboutScreenStrings
import ca.sebleclerc.localization.strings.ui.EnglishAdminPanelStrings
import ca.sebleclerc.localization.strings.ui.EnglishBlogStrings
import ca.sebleclerc.localization.strings.ui.EnglishDatabaseInfoStrings
import ca.sebleclerc.localization.strings.ui.EnglishEventDetailsStrings
import ca.sebleclerc.localization.strings.ui.EnglishEventListStrings
import ca.sebleclerc.localization.strings.ui.EnglishMapStrings
import ca.sebleclerc.localization.strings.ui.EnglishPlaceDetailsStrings
import ca.sebleclerc.localization.strings.ui.EnglishSiteStrings
import ca.sebleclerc.localization.strings.ui.EventDetailsStrings
import ca.sebleclerc.localization.strings.ui.EventListStrings
import ca.sebleclerc.localization.strings.ui.FrenchAboutScreenStrings
import ca.sebleclerc.localization.strings.ui.FrenchAdminPanelStrings
import ca.sebleclerc.localization.strings.ui.FrenchBlogStrings
import ca.sebleclerc.localization.strings.ui.FrenchDatabaseInfoStrings
import ca.sebleclerc.localization.strings.ui.FrenchEventDetailsStrings
import ca.sebleclerc.localization.strings.ui.FrenchEventListStrings
import ca.sebleclerc.localization.strings.ui.FrenchMapStrings
import ca.sebleclerc.localization.strings.ui.FrenchPlaceDetailsStrings
import ca.sebleclerc.localization.strings.ui.FrenchSiteStrings
import ca.sebleclerc.localization.strings.ui.MapStrings
import ca.sebleclerc.localization.strings.ui.PlaceDetailsStrings
import ca.sebleclerc.localization.strings.ui.SiteStrings

interface UIStrings {
  val about: AboutScreenStrings
  val adminPanel: AdminPanelStrings
  val blog: BlogStrings
  val databaseInfo: DatabaseInfoStrings
  val eventDetails: EventDetailsStrings
  val eventList: EventListStrings
  val map: MapStrings
  val placeDetails: PlaceDetailsStrings
  val site: SiteStrings
}

class EnglishUIStrings(modelStrings: ModelStrings) : UIStrings {
  override val about = EnglishAboutScreenStrings()
  override val adminPanel = EnglishAdminPanelStrings()
  override val blog = EnglishBlogStrings()
  override val databaseInfo = EnglishDatabaseInfoStrings()
  override val eventDetails = EnglishEventDetailsStrings(modelStrings.day)
  override val eventList = EnglishEventListStrings()
  override val map = EnglishMapStrings()
  override val placeDetails = EnglishPlaceDetailsStrings()
  override val site = EnglishSiteStrings
}

class FrenchUIStrings(modelStrings: ModelStrings) : UIStrings {
  override val about = FrenchAboutScreenStrings()
  override val adminPanel = FrenchAdminPanelStrings()
  override val blog = FrenchBlogStrings()
  override val databaseInfo = FrenchDatabaseInfoStrings()
  override val eventDetails = FrenchEventDetailsStrings(modelStrings.day)
  override val eventList = FrenchEventListStrings()
  override val map = FrenchMapStrings()
  override val placeDetails = FrenchPlaceDetailsStrings()
  override val site = FrenchSiteStrings
}
