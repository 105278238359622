package ca.sebleclerc.siteapp.pages

import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import ca.sebleclerc.core.models.EventEditionYear
import ca.sebleclerc.localization.strings.ModelStrings
import ca.sebleclerc.siteapp.components.fh5co.Features
import ca.sebleclerc.siteapp.components.fh5co.Header
import ca.sebleclerc.siteapp.components.fh5co.NavFooter
import ca.sebleclerc.siteapp.models.FeatureEntryItem
import ca.sebleclerc.siteapp.models.FeatureEntryItemText
import ca.sebleclerc.siteapp.repositories.EventsRepositoryable
import ca.sebleclerc.siteapp.services.Localizable
import org.jetbrains.compose.web.dom.Div
import org.kodein.di.compose.localDI
import org.kodein.di.instance

@Composable
fun EventsPage() {
  val di = localDI()
  val l11n: Localizable by di.instance()
  val locale = l11n.locale.collectAsState()
  val strings = l11n.getLocalizedStrings(locale.value)
  val repo: EventsRepositoryable by di.instance()

  val futureEvents = repo.futureEvents.collectAsState()
  val passedEvents = repo.passedEvents.collectAsState()

  val entries = mutableListOf<FeatureEntryItem>()
  entries.addAll(futureEvents.value.map { it.toFeatureEntry(strings.model) })

  if (futureEvents.value.isNotEmpty() && passedEvents.value.isNotEmpty()) {
    if (futureEvents.value.size % 2 != 0) {
      entries.add(FeatureEntryItemText.emptyEntry())
    }

    entries.add(
      FeatureEntryItemText(
        strings.ui.eventList.passedSectionTitle,
        emptyList(),
        emptyList()
      )
    )

    entries.add(FeatureEntryItemText.emptyEntry())
  }

  entries.addAll(passedEvents.value.map { it.toFeatureEntry(strings.model) })

  Div({ id("page-wrap") }) {
    Header(false)
    Features(entries)
    NavFooter()
  }
}

fun EventEditionYear.toFeatureEntry(modelStrings: ModelStrings): FeatureEntryItem {
  val details = mutableListOf(displayDates)

  for (day in days) {
    val dow = modelStrings.dayOfWeek(day.dayOfWeek)
    val start = day.startTime.hhmmValue
    val end = day.endTime.hhmmValue
    details.add("$dow $start - $end")
  }

  return FeatureEntryItemText(
    title,
    details,
    contacts
  )
}
