package ca.sebleclerc.network.models

import ca.sebleclerc.core.models.Event
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class APIEvent(
  val id: Int,
  val nom: String,
  @SerialName("nom_alternatif")
  val nomAlternatif: String?,
  @SerialName("adresse")
  val adresse: APIAdresse?,
  val notes: String?,
  @SerialName("contact_ids")
  val contactIds: String?,
  @SerialName("image_ids")
  val imageIds: String?
)

fun APIEvent.toDomain(): Event {
  return Event(
    id,
    nom,
    nomAlternatif,
    adresse?.toDomain(),
    notes,
    emptyList(),
    emptyList()
  )
}

fun Event.toAPI(): APIEvent {
  return APIEvent(
    id,
    nom,
    nomAlternatif,
    adresse?.toAPI(),
    notes,
    null,
    null
  )
}
