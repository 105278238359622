package ca.sebleclerc.siteapp

import androidx.compose.runtime.Composable
import app.softwork.routingcompose.HashRouter
import ca.sebleclerc.siteapp.pages.DownloadPage
import ca.sebleclerc.siteapp.pages.EventsPage
import ca.sebleclerc.siteapp.pages.LandingPage
import ca.sebleclerc.siteapp.pages.MapPage
import ca.sebleclerc.siteapp.pages.PrivacyPolicy
import org.jetbrains.compose.web.dom.Text

@Composable
fun Router() {
  HashRouter(initPath = "/") {
    route("/") {
      LandingPage()
    }

    route("/map") {
      MapPage()
    }

    route("/downloads") {
      DownloadPage()
    }

    route("/evenements") {
      EventsPage()
    }

    route("/privacy-policy") {
      PrivacyPolicy()
    }

    noMatch {
      Text("NO MATCH \uD83D\uDE22")
    }
  }
}
