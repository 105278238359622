package ca.sebleclerc.siteapp.pages

import androidx.compose.runtime.Composable
import ca.sebleclerc.siteapp.components.fh5co.Header
import ca.sebleclerc.siteapp.components.fh5co.NavFooter
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.H2
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text

@Composable
fun PrivacyPolicy() {
  Div({ id("page-wrap") }) {
    Header(false)
    Div {
      H2 { Text("Politique de confidentialité") }
      P { Text("Nous nous engageons à ne collecter aucun renseignement personnel, d'aucune sorte que ce soit.") } // ktlint-disable max-line-length argument-list-wrapping argument-list-wrapping

      H2 { Text("Privacy Policy") }
      P { Text("We are committed to collecting no personal information of any kind.") }
    }
    NavFooter()
  }
}
