package ca.sebleclerc.siteapp.repositories

import ca.sebleclerc.core.models.Day
import ca.sebleclerc.core.models.EventEditionYear
import ca.sebleclerc.network.IAPIService
import ca.sebleclerc.network.models.toDomain
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.asStateFlow

interface EventsRepositoryable : Repositoryable {
  val futureEvents: StateFlow<List<EventEditionYear>>
  val passedEvents: StateFlow<List<EventEditionYear>>

  suspend fun fetch()
}

class EventsRepository(apiService: IAPIService) : Repository(apiService), EventsRepositoryable {
  private var mutableFutureEvents = MutableStateFlow(emptyList<EventEditionYear>())
  override val futureEvents = mutableFutureEvents.asStateFlow()
  private var mutablePassedEvents = MutableStateFlow(emptyList<EventEditionYear>())
  override val passedEvents = mutablePassedEvents.asStateFlow()

  override suspend fun fetch() {
    val today = Day.today()
    val events = apiService.getEvents()
    val year = "2024"
    // TODO Add a dropdown to select date
    val editions = apiService
      .getYearlyEditions(year)
      .map { it.toDomain() }
      .sortedBy { it.startDate }
    val days = apiService.getYearlyEditionDays(year)

    val future = mutableListOf<EventEditionYear>()
    val passed = mutableListOf<EventEditionYear>()

    for (edition in editions) {
      val event = events.first { it.id == edition.idEvent }.toDomain()

      val contacts = apiService
        .getEventContacts(event.id)
        .map { it.toDomain() }
        .sortedBy { it.idTypeContact }
      event.contacts = contacts

      val editionYear = EventEditionYear(
        event,
        edition,
        days.filter { it.idEventEdition == edition.id }.map { it.toDomain() }
      )

      if (edition.endDate >= today) {
        future.add(editionYear)
      } else {
        passed.add(editionYear)
      }
    }

    mutableFutureEvents.value = future
    mutablePassedEvents.value = passed
  }
}
