package ca.sebleclerc.core.models

class Time(val hour: Int, val minute: Int) {
  companion object {}

  val hhmmValue: String
    get() {
      var hourValue = ""

      if (hour > 9) {
        hourValue = "$hour"
      } else {
        hourValue = "0$hour"
      }

      var minValue = ""

      if (minute > 9) {
        minValue = "$minute"
      } else {
        minValue = "0$minute"
      }

      return "$hourValue:$minValue"
    }
}
