package ca.sebleclerc.localization.models

enum class Langue(val id: String) {
  FRENCH("FR") {
    override val displayTitle: String
      get() = id

    override fun toggle(): Langue {
      return ENGLISH
    }
  },
  ENGLISH("EN") {
    override val displayTitle: String
      get() = id

    override fun toggle(): Langue {
      return FRENCH
    }
  };

  abstract val displayTitle: String
  abstract fun toggle(): Langue
}
