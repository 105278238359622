package ca.sebleclerc.localization.strings

import ca.sebleclerc.core.models.DayOfWeek
import ca.sebleclerc.localization.strings.model.DayStrings
import ca.sebleclerc.localization.strings.model.EnglishDayStrings
import ca.sebleclerc.localization.strings.model.EnglishTypeEtablissementStrings
import ca.sebleclerc.localization.strings.model.FrenchDayStrings
import ca.sebleclerc.localization.strings.model.FrenchTypeEtablissementStrings
import ca.sebleclerc.localization.strings.model.TypeEtablissementStrings

interface ModelStrings {
  val day: DayStrings
  val typeEtablissement: TypeEtablissementStrings

  val toMidnightTime: String

  fun dayOfWeek(dow: DayOfWeek): String
}

class EnglishModelStrings : ModelStrings {
  override val day = EnglishDayStrings()
  override val typeEtablissement = EnglishTypeEtablissementStrings()

  override val toMidnightTime = "to midnight"

  override fun dayOfWeek(dow: DayOfWeek): String {
    return when (dow) {
      DayOfWeek.Dimanche -> "Sunday"
      DayOfWeek.Lundi -> "Monday"
      DayOfWeek.Mardi -> "Tuesday"
      DayOfWeek.Mercredi -> "Wednesday"
      DayOfWeek.Jeudi -> "Thursday"
      DayOfWeek.Vendredi -> "Friday"
      DayOfWeek.Samedi -> "Saturday"
    }
  }
}

class FrenchModelStrings : ModelStrings {
  override val day = FrenchDayStrings()
  override val typeEtablissement = FrenchTypeEtablissementStrings()

  override val toMidnightTime = "à minuit"

  override fun dayOfWeek(dow: DayOfWeek): String {
    return when (dow) {
      DayOfWeek.Dimanche -> "Dimanche"
      DayOfWeek.Lundi -> "Lundi"
      DayOfWeek.Mardi -> "Mardi"
      DayOfWeek.Mercredi -> "Mercredi"
      DayOfWeek.Jeudi -> "Jeudi"
      DayOfWeek.Vendredi -> "Vendredi"
      DayOfWeek.Samedi -> "Samedi"
    }
  }
}
