package ca.sebleclerc.siteapp.pages

import androidx.compose.runtime.Composable
import ca.sebleclerc.siteapp.components.LeafletMap
import ca.sebleclerc.siteapp.components.LeafletMarker
import ca.sebleclerc.siteapp.components.fh5co.Header
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text

@Composable
fun MapPage() {
  Div({ id("page-wrap") }) {
    Header(false) {
      LeafletMap({
        style {
          height(500.px)
          width(500.px)
        }
      }) {
        LeafletMarker({
          style {
            property("latitude", "45.52934688366481")
            property("longitude", "-73.44588957909691")
          }
        }) {
          Text("Maison")
        }
      }
    }
  }
}
