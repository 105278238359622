package ca.sebleclerc.localization

import ca.sebleclerc.localization.models.Langue

class StringsService {
  private val defaultLocale = Langue.FRENCH

  private val allLocalizedStrings = mapOf(
    Langue.ENGLISH to EnglishLocalizedStrings(),
    Langue.FRENCH to FrenchLocalizedStrings()
  )

  fun getLocalizedStrings(locale: Langue): LocalizedStrings {
    val localeString = allLocalizedStrings[locale]

    return localeString ?: allLocalizedStrings[defaultLocale]!!
  }
}
