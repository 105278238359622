package ca.sebleclerc.siteapp.components.fh5co

import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import ca.sebleclerc.siteapp.services.Localizable
import org.jetbrains.compose.web.dom.A
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Footer
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import org.kodein.di.compose.localDI
import org.kodein.di.instance

@Composable
fun NavFooter() {
  val di = localDI()
  val l11n: Localizable by di.instance()
  val locale = l11n.locale.collectAsState()
  val strings = l11n.getLocalizedStrings(locale.value)

  Footer({ classes("footer-outer") }) {
    Div({ classes("container", "footer-inner") }) {
//      Div({ classes("footer-three-grid", "wow", "fadeIn", "animated") }) {
//        Div({ classes("column-1-3") }) {
//          H1 { Text("42 Bières") }
//        }
//
//        Div({ classes("column-2-3") }) {
//          Nav({ classes("footer-nav") }) {
//            // List of links
//            // Check on NavBar
//          }
//        }
//
//        Div({ classes("column-3-3") }) {
//          Div({ classes("social-icons-footer") }) {
//            // List of social icons
//            // Check on NavBar
//          }
//        }
//      }

      Span({ classes("border-bottom-footer") })

      P({ classes("copyright") }) {
        Text(strings.ui.about.copyright("6.3.1"))
        Text(". Tous droits réservés. Design by ")
        A(href = "https://freehtml5.co") { Text("FreeHTML5") }
      }
    }
  }
}
