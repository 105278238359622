package ca.sebleclerc.siteapp.pages

import androidx.compose.runtime.Composable
import ca.sebleclerc.siteapp.components.fh5co.Downloads
import ca.sebleclerc.siteapp.components.fh5co.Header
import ca.sebleclerc.siteapp.components.fh5co.NavFooter
import org.jetbrains.compose.web.dom.Div

@Composable
fun DownloadPage() {
  Div({
    id("page-wrap")
  }) {
    Header(false)
    Downloads()
    NavFooter()
  }
}
