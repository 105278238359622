package ca.sebleclerc.localization.strings.ui

import ca.sebleclerc.localization.strings.ui.site.DownloadStrings
import ca.sebleclerc.localization.strings.ui.site.EnDownloadStrings
import ca.sebleclerc.localization.strings.ui.site.EnHomeStrings
import ca.sebleclerc.localization.strings.ui.site.EnNavBarStrings
import ca.sebleclerc.localization.strings.ui.site.FrDownloadStrings
import ca.sebleclerc.localization.strings.ui.site.FrHomeStrings
import ca.sebleclerc.localization.strings.ui.site.FrNavBarStrings
import ca.sebleclerc.localization.strings.ui.site.HomeStrings
import ca.sebleclerc.localization.strings.ui.site.NavBarStrings

interface SiteStrings {
  val download: DownloadStrings
  val home: HomeStrings
  val navBar: NavBarStrings
}

object EnglishSiteStrings : SiteStrings {
  override val download = EnDownloadStrings
  override val home = EnHomeStrings
  override val navBar = EnNavBarStrings
}

object FrenchSiteStrings : SiteStrings {
  override val download = FrDownloadStrings
  override val home = FrHomeStrings
  override val navBar = FrNavBarStrings
}
