package ca.sebleclerc.siteapp.components.fh5co

import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import ca.sebleclerc.siteapp.services.Localizable
import kotlinx.browser.window
import org.jetbrains.compose.web.dom.Button
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.H1
import org.jetbrains.compose.web.dom.Img
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text
import org.kodein.di.compose.localDI
import org.kodein.di.instance

@Composable
fun Hero() {
  val di = localDI()
  val l11n: Localizable by di.instance()
  val locale = l11n.locale.collectAsState()
  val strings = l11n.getLocalizedStrings(locale.value)

  Div({
    classes("container", "fh5co-hero-inner")
  }) {
    H1({
      apply { attr("data-wow-delay", "0.4s") }
      classes("animated", "fadeIn", "wow")
    }) { Text(strings.ui.site.home.hero.title) }

    P({
      apply { attr("data-wow-delay", "0.67s") }
      classes("animated", "fadeIn", "wow")
    }) { Text(strings.ui.site.home.hero.subtitle) }

    Button({
      apply { attr("data-wow-delay", "0.85s") }
      classes("btn", "btn-md", "download-btn-first", "wow", "fadeInLeft", "animated")
      onClick { window.location.href = "#/downloads" }
    }) { Text(strings.ui.site.navBar.download) }

//    Button({
//      apply { attr("data-wow-delay", "0.95s") }
//      classes("btn", "btn-md", "features-btn-first", "animated", "fadeInLeft", "wow")
//      onClick { router.navigate("/map") }
//    }) { Text(l18n.strings.home.hero.featuresButton) }

    Img(
      src = "img/phone-image.png",
      alt = "Smartphone"
    ) {
      classes("fh5co-hero-smartphone", "animated", "fadeInRight", "wow")
      apply { attr("data-wow-delay", "1s") }
    }
  }
}
