package ca.sebleclerc.localization.strings.ui.site

interface NavBarStrings {
  val accueil: String
  val download: String
  val events: String
}

object EnNavBarStrings : NavBarStrings {
  override val accueil = "Home"
  override val download = "Download"
  override val events = "Events"
}

object FrNavBarStrings : NavBarStrings {
  override val accueil = "Accueil"
  override val download = "Télécharger"
  override val events = "Événements"
}
