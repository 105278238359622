package ca.sebleclerc.localization.strings.ui

// AboutScreen
interface AboutScreenStrings {
  val title: String

  val aboutSectionTitle: String
  val aboutPart1: String
  val aboutPart2: String

  val contactSectionTitle: String

  val moreSectionTitle: String
  val rateApp: String
  val openSource: String
  val buyMeCoffeeMessage: String
  val thankYouMessage: String

  val ossOrion: String
  val ossSVHud: String
  val ossLayout: String
  val ossCluster: String
  val ossRSSParser: String
  val ossWebImage: String

  val emailSubject: String
  val cantSendEmailTitle: String
  val cantSendEmailMesage: String

  fun copyright(version: String): String
}

/* ktlint-disable max-line-length */

// English

class EnglishAboutScreenStrings : AboutScreenStrings {
  override val title = "Info"

  override val aboutSectionTitle = "About"
  override val aboutPart1 = "The 42 Bières app lets you discover places from Quebec. Using geolocalisation, you will find places nearby as you travel all around the province."
  override val aboutPart2 = "42 Bières is also a beer blog. It informs you about the beer world and the Quebec beer scene."

  override val contactSectionTitle = "Contact"

  override val moreSectionTitle = "More"
  override val rateApp = "Rate my app"
  override val openSource = "Open source softwares"
  override val buyMeCoffeeMessage = "Buy me a beer!"
  override val thankYouMessage = "Thanks you for supporting \uD83C\uDF89 42 Bières !"

  override val ossOrion = "Free to use icons for multiple purposes."
  override val ossSVHud = "A clean and lightweight progress HUD for iOS and tvOS app."
  override val ossLayout = "A declarative UI framework for iOS."
  override val ossCluster = "Easy Map Annotation Clustering."
  override val ossRSSParser = "Simple RSS parser for Objective-C."
  override val ossWebImage = "A library that provide an async image downloader with cache support."

  override val emailSubject = "About 42 Bières mobile app"
  override val cantSendEmailTitle = "Can't send email"
  override val cantSendEmailMesage = "No email is configured. You should start there."

  override fun copyright(version: String): String {
    return "© 2013-2024 Services 42B Inc. - v$version"
  }
}

// French

class FrenchAboutScreenStrings : AboutScreenStrings {
  override val title = "Info"

  override val aboutSectionTitle = "À Propos"
  override val aboutPart1 = "L'app 42 Bières te permet de découvrir les endroits qui t'entourent au Québec. À l'aide de la géolocalisation, tu trouveras de nouvelles destinations brassicoles au fil de tes voyages à travers la province."
  override val aboutPart2 = "42 Bières c'est aussi un blogue sur la bière. Il contient de l'information sur le monde de la bière et la scène brassicole québécoise."

  override val contactSectionTitle = "Contact"

  override val moreSectionTitle = "Plus"
  override val rateApp = "Évaluer mon application"
  override val openSource = "Logiciels libres"
  override val buyMeCoffeeMessage = "Paie-moi une bière!"
  override val thankYouMessage = "Merci de supporter \uD83C\uDF89 42 Bières!"

  override val ossOrion = "Des icônes gratuits à des fins multiples."
  override val ossSVHud = "Un HUD de progrès propre et léger pour iOS et app tvOS."
  override val ossLayout = "Un framework d'interface utilisateur déclaratif pour iOS."
  override val ossCluster = "Facilement faire des regroupements sur une carte."
  override val ossRSSParser = "Analyseur RSS simple pour Objective-C."
  override val ossWebImage = "Une bibliothèque qui offre le téléchargement asynchrone d'images."

  override val emailSubject = "À propos de l'application mobile 42 Bières"
  override val cantSendEmailTitle = "Impossible d'envoyer un courriel"
  override val cantSendEmailMesage = "Aucun compte courriel n'est configuré. Tu devrais commencer par ça."

  override fun copyright(version: String): String {
    return "© 2013-2024 Services 42B Inc. - v$version"
  }
}
