package ca.sebleclerc.localization.strings.ui

interface PlaceDetailsStrings {
  val notFoundTitle: String

  val adresseHeader: String

  val accessHeader: String
  val accessCanDrink: String
  val accessCanEat: String
  val accessHasBoutique: String
  val accessDogFriendly: String

  val contactsHeader: String
  val contactsFooter: String

  val notesHeader: String

  val exportButton: String
  val settingsButton: String

  val openSettingsTitle: String
  val openSettingsDescription: String
}

class EnglishPlaceDetailsStrings : PlaceDetailsStrings {
  override val notFoundTitle = "Place not found"

  override val adresseHeader = "Address"

  override val accessHeader = "Access"
  override val accessCanDrink = "Can drink on site?"
  override val accessCanEat = "Can eat on site?"
  override val accessHasBoutique = "Can purchase on site?"
  override val accessDogFriendly = "Is dog friendly?"

  override val contactsHeader = "Contacts"
  override val contactsFooter = "Always contact each place before traveling."

  override val notesHeader = "Notes"

  override val exportButton = "Create an event here"
  override val settingsButton = "Open Settings"

  override val openSettingsTitle = "Calendar Access Required"

  /* ktlint-disable max-line-length */
  override val openSettingsDescription = "If you want to create a new event, 42 Bières app needs to have access to your calendar."
  /* ktlint-enable max-line-length */
}

class FrenchPlaceDetailsStrings : PlaceDetailsStrings {
  override val notFoundTitle = "Établissement introuvable"

  override val adresseHeader = "Adresse"

  override val accessHeader = "Accès"
  override val accessCanDrink = "Peut boire sur place?"
  override val accessCanEat = "Peut manger sur place?"
  override val accessHasBoutique = "Peut acheter sur place?"
  override val accessDogFriendly = "Ouvert aux chiens?"

  override val contactsHeader = "Contacts"
  override val contactsFooter = "Toujours contacter les établissements avant de vous déplacer."

  override val notesHeader = "Notes"

  override val exportButton = "Créer un événement ici"
  override val settingsButton = "Ouvrir les réglages"

  override val openSettingsTitle = "Accès au calendrier requis"

  /* ktlint-disable max-line-length */
  override val openSettingsDescription = "Pour créer un événement, l'app 42 Bières a besoin d'avoir accès à ton calendrier."
  /* ktlint-enable max-line-length */
}
