package ca.sebleclerc.localization.strings.ui

import ca.sebleclerc.core.ui.MapFilterTypes

interface MapStrings {
  val navItemTitle: String
  val filterTitle: String

  fun filterType(type: MapFilterTypes): String
}

class EnglishMapStrings : MapStrings {
  override val navItemTitle = "42 Bières Map"
  override val filterTitle = "Filter places"

  override fun filterType(type: MapFilterTypes): String {
    when (type) {
      MapFilterTypes.All -> return "All"
      MapFilterTypes.Detaillants -> return "Retailers"
      MapFilterTypes.Favoris -> return "Favorites"
      MapFilterTypes.Microbrasseries -> return "Micro / Brewpub"
      MapFilterTypes.Restaurants -> return "Restaurant / Bar"
      MapFilterTypes.Visited -> return "Visited"
      else -> return ""
    }
  }
}

class FrenchMapStrings : MapStrings {
  override val navItemTitle = "Carte 42 Bières"
  override val filterTitle = "Filter les emplacements"

  override fun filterType(type: MapFilterTypes): String {
    when (type) {
      MapFilterTypes.All -> return "Tous"
      MapFilterTypes.Detaillants -> return "Détaillants"
      MapFilterTypes.Favoris -> return "Favoris"
      MapFilterTypes.Microbrasseries -> return "Microbrasseries"
      MapFilterTypes.Restaurants -> return "Restaurant / Bar"
      MapFilterTypes.Visited -> return "Visité"
      else -> return ""
    }
  }
}
