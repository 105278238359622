package ca.sebleclerc.siteapp.pages

import androidx.compose.runtime.Composable
import ca.sebleclerc.siteapp.components.fh5co.Header
import ca.sebleclerc.siteapp.components.fh5co.NavFooter
import org.jetbrains.compose.web.dom.Div

@Composable
fun LandingPage() {
  Div({
    id("page-wrap")
  }) {
    Header(true)
//    Advantages()
//    Slider()
    NavFooter()
  }
}

private val pageContent: String = """
<!-- ==========================================================================================================
                        REVIEWS
 ========================================================================================================== -->

<div id="fh5co-reviews" class="fh5co-reviews-outer">
  <h1>What people are saying</h1>
  <small>Reviews</small>
  <div class="container fh5co-reviews-inner">
    <div class="row justify-content-center">
      <div class="col-sm-5 wow fadeIn animated" data-wow-delay="0.25s">
        <img class="float-left" src="img/quotes-1.jpg" alt="Quote 1">
        <p class="testimonial-desc">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quis
          similique quasi.</p>
        <small class="testimonial-author">John Doe</small>
        <img class="float-right" src="img/quotes-2.jpg" alt="Quote 2">
      </div>
      <div class="col-sm-5 testimonial-2 wow fadeIn animated" data-wow-delay="0.67s">
        <img class="float-left" src="img/quotes-1.jpg" alt="Quote 1">
        <p class="testimonial-desc">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quis
          similique quasi.</p>
        <small class="testimonial-author">Someone</small>
        <img class="float-right" src="img/quotes-2.jpg" alt="Quote 2">
      </div>
    </div>

  </div>
</div>
""".trimIndent()
