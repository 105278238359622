package ca.sebleclerc.localization.strings

interface GenericStrings {
  val calendar: String
  val cancel: String
  val download: String
  val email: String
  val error: String
  val favorites: String
  val map: String
  val no: String
  val notes: String
  val ok: String
  val success: String
  val website: String
  val yes: String
}

class EnglishGenericString : GenericStrings {
  override val calendar = "Calendar"
  override val cancel = "Cancel"
  override val download = "Download"
  override val email = "Email"
  override val error = "Error"
  override val favorites = "Favorites"
  override val map = "Map"
  override val no = "No"
  override val notes = "Notes"
  override val ok = "Ok"
  override val success = "Success"
  override val website = "Website"
  override val yes = "Yes"
}

class FrenchGenericStrings : GenericStrings {
  override val calendar = "Calendar"
  override val cancel = "Annuler"
  override val download = "Télécharger"
  override val email = "Courriel"
  override val error = "Erreur"
  override val favorites = "Favoris"
  override val map = "Carte"
  override val no = "Non"
  override val notes = "Notes"
  override val ok = "Ok"
  override val success = "Succès"
  override val website = "Site web"
  override val yes = "Oui"
}
