package ca.sebleclerc.siteapp.components.fh5co

import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import ca.sebleclerc.siteapp.services.Localizable
import org.jetbrains.compose.web.attributes.ATarget
import org.jetbrains.compose.web.attributes.ButtonType
import org.jetbrains.compose.web.attributes.target
import org.jetbrains.compose.web.attributes.type
import org.jetbrains.compose.web.dom.A
import org.jetbrains.compose.web.dom.Button
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.I
import org.jetbrains.compose.web.dom.Li
import org.jetbrains.compose.web.dom.Nav
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import org.jetbrains.compose.web.dom.Ul
import org.kodein.di.compose.localDI
import org.kodein.di.instance

data class NavBarItem(val href: String?, val title: String, val onClick: (() -> Unit)? = null)
data class NavBarSocial(val href: String, val icon: String)

@Composable
fun NavBar() {
  val di = localDI()
  val l11n: Localizable by di.instance()
  val locale = l11n.locale.collectAsState()
  val strings = l11n.getLocalizedStrings(locale.value)

  val items = listOf(
    NavBarItem("#/", strings.ui.site.navBar.accueil),
    NavBarItem("#/downloads", strings.ui.site.navBar.download),
    NavBarItem("#/evenements", strings.ui.site.navBar.events)
  )

  val socials = listOf(
    NavBarSocial("https://www.facebook.com/42bieres", "fa-facebook-f"),
    NavBarSocial("https://instagram.com/42bieres", "fa-instagram"),
    NavBarSocial("https://twitter.com/42bieres", "fa-twitter")
  )

  Nav({
    classes("container", "navbar", "navbar-expand-lg", "main-navbar-nav", "navbar-light")
  }) {
    A(href = "#/", { classes("navbar-brand") }) {
      Text("42 Bières")
    }

    Button({
      classes("navbar-toggler")
      type(ButtonType.Button)
    }) {
      Span({ classes("navbar-toggler-icon") })
    }

    Div({
      id("navbarSupportedContent")
      classes("collapse", "navbar-collapse")
    }) {
      Ul({ classes("navbar-nav", "nav-items-center", "ml-auto", "mr-auto") }) {
        for (item in items) {
          Li({ classes("nav-item", "active") }) {
            A(href = item.href, {
              classes("nav-link")
            }) {
              Text(item.title)
            }
          }
        }

        Li({ classes("nav-item", "active") }) {
          Button({
            classes("nav-link")
            onClick { l11n.updateLocale(locale.value.toggle()) }
          }) {
            Text(locale.value.toggle().displayTitle)
          }
        }
      }

      Div({ classes("social-icons-header") }) {
        for (social in socials) {
          A(href = social.href, { target(ATarget.Blank) }) {
            I({ classes("fab", social.icon) })
          }
        }
      }
    }
  }
}
