package ca.sebleclerc.siteapp.components

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.ContentBuilder
import org.jetbrains.compose.web.dom.TagElement
import org.w3c.dom.HTMLDivElement

@Composable
fun LeafletMap(
  attrs: AttrBuilderContext<HTMLDivElement>? = null,
  content: ContentBuilder<HTMLDivElement>? = null
) {
  TagElement(
    "leaflet-map",
    applyAttrs = attrs,
    content = content
  )
}
