package ca.sebleclerc.localization.strings.model

interface TypeEtablissementStrings {
  val inconnu: String
  val microbrasserie: String
  val salonDegustation: String
  val brasserieArtisanale: String
  val detaillant: String
  val restaurant: String
  val bar: String
}

class EnglishTypeEtablissementStrings : TypeEtablissementStrings {
  override val inconnu = "Unknown"
  override val microbrasserie = "Microbrewery"
  override val salonDegustation = "Tasting Room"
  override val brasserieArtisanale = "Brewpub"
  override val detaillant = "Retailer"
  override val restaurant = "Restaurant"
  override val bar = "Bar"
}

class FrenchTypeEtablissementStrings : TypeEtablissementStrings {
  override val inconnu = "Inconnu"
  override val microbrasserie = "Microbrasserie"
  override val salonDegustation = "Salon de dégustaion"
  override val brasserieArtisanale = "Brasserie Artisanale"
  override val detaillant = "Détaillant"
  override val restaurant = "Restaurant"
  override val bar = "Bar"
}
