package ca.sebleclerc.localization.strings.ui

interface EventListStrings {
  val listTitle: String

  val upcomingSectionTitle: String
  val passedSectionTitle: String
  val archivedSectionTitle: String
}

class EnglishEventListStrings : EventListStrings {
  override val listTitle = "All Events"

  override val upcomingSectionTitle = "Upcoming"
  override val passedSectionTitle = "In The Past"
  override val archivedSectionTitle = "Archived"
}

class FrenchEventListStrings : EventListStrings {
  override val listTitle = "Tous les événements"

  override val upcomingSectionTitle = "À venir"
  override val passedSectionTitle = "Passé"
  override val archivedSectionTitle = "Archivé"
}
