package ca.sebleclerc.core.models

import kotlinx.datetime.Clock
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime

class Day(val year: Int, val month: Int, val day: Int) : Comparable<Day> {

  // Companion object contains static elements
  companion object {
    val Empty = Day(0, 0, 0)

    fun today(): Day {
      val now = Clock.System.now().toLocalDateTime(TimeZone.currentSystemDefault())
      return Day(now.year, now.monthNumber, now.dayOfMonth)
    }
  }

  val YMDstring: String
    get() {
      var monthValue: String = ""

      if (month > 9) {
        monthValue = "$month"
      } else {
        monthValue = "0$month"
      }

      var dayValue: String = ""

      if (day > 9) {
        dayValue = "$day"
      } else {
        dayValue = "0$day"
      }

      return "$year-$monthValue-$dayValue"
    }

  override fun compareTo(other: Day): Int {
    val result: Int

    if (year < other.year) {
      result = -1
    } else if (year > other.year) {
      result = 1
    } else if (month < other.month) {
      result = -1
    } else if (month > other.month) {
      result = 1
    } else if (day < other.day) {
      result = -1
    } else if (day > other.day) {
      result = 1
    } else {
      result = 0
    }

    return result
  }
}
