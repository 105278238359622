package ca.sebleclerc.core.models

class EventEditionYear(
  private val event: Event,
  private val edition: EventEdition,
  var days: List<EventEditionDay>
) {
  companion object {}

  val name = event.nomAlternatif ?: event.nom
  val title = "$name (${edition.year})"
  val idEdition = edition.id
  val contacts: List<Contact>
    get() = event.contacts

  val images: List<Image>
    get() = event.images

  val displayDates: String
    get() {
      return when {
        isOneDay -> startDate.YMDstring
        else -> YMDIntervalString
      }
    }

  val startDate: Day
    get() = Day(edition.startDateYear, edition.startDateMonth, edition.startDateDay)

  val endDate: Day
    get() = Day(edition.endDateYear, edition.endDateMonth, edition.endDateDay)

  private val hasEditionDays: Boolean
    get() = days.isNotEmpty()

  val YMDIntervalString: String
    get() {
      return if (startDate.compareTo(endDate) == 0) {
        startDate.YMDstring
      } else {
        "${startDate.YMDstring} - ${endDate.YMDstring}"
      }
    }

  val isOneDay: Boolean
    get() = startDate.compareTo(endDate) == 0

  val hasAdresse: Boolean
    get() = event.adresse != null && edition.adresse != null

  val adresse: Adresse?
    get() = edition.adresse ?: event.adresse

  val websiteContact: Contact?
    get() = getContactFor(TypeContact.SiteWeb)

  val emailContact: Contact?
    get() = getContactFor(TypeContact.Courriel)

  val facebookContact: Contact?
    get() = getContactFor(TypeContact.Facebook)

  val twitterContact: Contact?
    get() = getContactFor(TypeContact.Twitter)

  val instagramContact: Contact?
    get() = getContactFor(TypeContact.Instagram)

  val telephoneContact: Contact?
    get() = getContactFor(TypeContact.NumTelephone)

  val youtubeContact: Contact?
    get() = getContactFor(TypeContact.Youtube)

  private fun getContactFor(typeContact: TypeContact): Contact? {
    for (contact in event.contacts) {
      if (contact.typeContact == typeContact) {
        return contact
      }
    }

    return null
  }
}
