package ca.sebleclerc.localization.strings.ui.site

interface DownloadStrings {
  val title: String
  val description: String
}

object EnDownloadStrings : DownloadStrings {
  override val title = "How to download the app?"
  override val description = "Just head over to the App Store or Google Play Store using one of these links." // ktlint-disable DownloadStrings.kt
}

object FrDownloadStrings : DownloadStrings {
  override val title = "Comment télécharger l'app?"
  override val description = "Tu n'as qu'à te rendre sur App Store ou dans le Google Play Store en utilisant un des liens ci-dessous." // ktlint-disable DownloadStrings.kt
}
