package ca.sebleclerc.siteapp.models

import androidx.compose.runtime.Composable
import ca.sebleclerc.core.models.Contact
import ca.sebleclerc.core.models.TypeContact
import org.jetbrains.compose.web.attributes.ATarget
import org.jetbrains.compose.web.attributes.target
import org.jetbrains.compose.web.css.marginLeft
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.A
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.H1
import org.jetbrains.compose.web.dom.I
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text

data class FeatureEntryItemText(
  val title: String,
  val texts: List<String>,
  val contacts: List<Contact>
) : FeatureEntryItem {

  companion object {
    fun emptyEntry(): FeatureEntryItemText {
      return FeatureEntryItemText("", emptyList(), emptyList())
    }
  }

  override val type: FeatureEntryItemType = FeatureEntryItemType.Text

  @Composable
  override fun getComponent(index: Int) {
    return Div({
      apply { attr("data-wow-delay", "0.22s") }
      classes("col-sm-6", "in-order-${index + 1}", "sm-6-content", "wow", "animated", "fadeInRight")
    }) {
      H1 { Text(title) }
      for (text in texts) {
        P { Text(text) }
      }

      contacts.forEachIndexed { index, contact ->
        ContactCircle(index, contact)
      }
    }
  }

  @Composable
  private fun ContactCircle(index: Int, contact: Contact) {
    val position = when (index) {
      0 -> "first"
      contacts.count() - 1 -> "last"
      else -> "middle"
    }

    val typeIds = when (contact.typeContact) {
      TypeContact.SiteWeb -> arrayOf("fas", "fa-at")
      TypeContact.Courriel -> arrayOf("fas", "fa-envelope-open")
      TypeContact.Facebook -> arrayOf("fab", "fa-facebook")
      TypeContact.Twitter -> arrayOf("fab", "fa-twitter")
      TypeContact.Instagram -> arrayOf("fab", "fa-instagram")
      TypeContact.GooglePlus -> arrayOf("fab", "fa-google-plus")
      TypeContact.NumTelephone -> arrayOf("fas", "fa-phone")
      TypeContact.Tumblr -> arrayOf("fab", "fa-tumblr")
      TypeContact.Youtube -> arrayOf("fab", "fa-youtube")
    }

    val marginLeft = 50 * index
    val adresse = if (contact.typeContact == TypeContact.Courriel) {
      "mailto:${contact.adresse}"
    } else {
      contact.adresse
    }

    return Span({
      classes("circle", "circle-$position")
      style { marginLeft(marginLeft.px) }
    }) {
      A(href = adresse, { target(ATarget.Blank) }) {
        I({ classes(*typeIds) })
      }
    }
  }
}
