package ca.sebleclerc.localization

import ca.sebleclerc.localization.strings.EnglishGenericString
import ca.sebleclerc.localization.strings.EnglishModelStrings
import ca.sebleclerc.localization.strings.EnglishUIStrings
import ca.sebleclerc.localization.strings.FrenchGenericStrings
import ca.sebleclerc.localization.strings.FrenchModelStrings
import ca.sebleclerc.localization.strings.FrenchUIStrings
import ca.sebleclerc.localization.strings.GenericStrings
import ca.sebleclerc.localization.strings.ModelStrings
import ca.sebleclerc.localization.strings.UIStrings

interface LocalizedStrings {
  val appName: String

  val generic: GenericStrings
  val model: ModelStrings
  val ui: UIStrings
}

class EnglishLocalizedStrings : LocalizedStrings {
  override val appName = "42 Bieres"

  override val generic = EnglishGenericString()
  override val model = EnglishModelStrings()
  override val ui = EnglishUIStrings(model)
}

class FrenchLocalizedStrings : LocalizedStrings {
  override val appName = "42 Bières"

  override val generic = FrenchGenericStrings()
  override val model = FrenchModelStrings()
  override val ui = FrenchUIStrings(model)
}
