package ca.sebleclerc.siteapp

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import ca.sebleclerc.core.Environment
import ca.sebleclerc.network.APIService
import ca.sebleclerc.network.IAPIService
import ca.sebleclerc.siteapp.repositories.EventsRepository
import ca.sebleclerc.siteapp.repositories.EventsRepositoryable
import ca.sebleclerc.siteapp.services.Localizable
import ca.sebleclerc.siteapp.services.LocalizationService
import org.kodein.di.DI
import org.kodein.di.bind
import org.kodein.di.compose.withDI
import org.kodein.di.instance
import org.kodein.di.singleton

val di = DI {
  bind<IAPIService> { singleton { APIService(Environment.Prod) } }
  bind<Localizable> { singleton { LocalizationService() } }
  bind<EventsRepositoryable> { singleton { EventsRepository(instance()) } }
}

@Composable
fun MainApp() = withDI(di) {
  val eventsRepo: EventsRepositoryable by di.instance()

  LaunchedEffect(true) {
    eventsRepo.fetch()
  }

  Router()
}
