package ca.sebleclerc.siteapp.components.fh5co

import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import ca.sebleclerc.siteapp.services.Localizable
import org.jetbrains.compose.web.dom.A
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.H1
import org.jetbrains.compose.web.dom.Img
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text
import org.kodein.di.compose.localDI
import org.kodein.di.instance

@Composable
fun Downloads() {
  val di = localDI()
  val l11n: Localizable by di.instance()
  val locale = l11n.locale.collectAsState()
  val strings = l11n.getLocalizedStrings(locale.value)

  Div({
    id("fh5co-download")
    classes("fh5co-bottom-outer")
  }) {
    Div({ classes("overlay") }) {
      Div({ classes("container", "fh5co-bottom-inner") }) {
        Div({ classes("row") }) {
          Div({ classes("col-sm-6") }) {
            H1 { Text(strings.ui.site.download.title) }

            P { Text(strings.ui.site.download.description) }

            A(href = "https://apps.apple.com/ca/app/42-bières-trouver-une-bière/id703282253?l=fr", {
              apply { attr("data-wow-delay", "0.25s") }
              classes("wow", "fadeIn", "animated")
            }) {
              Img(src = "img/app-store-icon.png", alt = "App Store Icon") {
                classes("app-store-btn")
              }
            }

            A(href = "https://play.google.com/store/apps/details?id=ca.sebleclerc.sll42bieres&hl=en_CA&gl=US", { // ktlint-disable max-line-length
              apply { attr("data-wow-delay", "0.67s") }
              classes("wow", "fadeIn", "animated")
            }) {
              Img(src = "img/google-play-icon.png", alt = "Google Play Icon") {
                classes("google-play-btn")
              }
            }
          }
        }
      }
    }
  }
}
