package ca.sebleclerc.siteapp.components.fh5co

import androidx.compose.runtime.Composable
import ca.sebleclerc.siteapp.models.FeatureEntryItem
import org.jetbrains.compose.web.dom.Div

@Composable
fun Features(items: List<FeatureEntryItem>, animated: Boolean = false) {
  Div {
    Div({
      if (animated) {
        apply { attr("data-wow-delay", "0.15s") }
        classes("curved-bg-div", "wow", "animated", "fadeIn")
      } else {
        classes("curved-bg-div")
      }
    })

    Div({
      id("fh5co-features")
      classes("fh5co-features-outer")
    }) {
      Div({ classes("container") }) {
        Div({ classes("row", "fh5co-features-grid-columns") }) {
          items.forEachIndexed { i, item ->
            item.getComponent(i)
          }
        }
      }
    }
  }
}
