package ca.sebleclerc.localization.strings.ui

interface BlogStrings {
  val title: String
}

class EnglishBlogStrings : BlogStrings {
  override val title = "Blog"
}

class FrenchBlogStrings : BlogStrings {
  override val title = "Blogue"
}
