package ca.sebleclerc.siteapp.components.fh5co

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Div

@Composable
fun Header(includeHero: Boolean, content: (@Composable () -> Unit)? = null) {
  val height = if (includeHero) 800.px else 100.px

  Div({
    id("fh5co-hero-wrapper")
    style { height(height) }
  }) {
    NavBar()
    if (includeHero) {
      Hero()
    }

    if (content != null) {
      content()
    }
  }
}
