package ca.sebleclerc.siteapp.services

import ca.sebleclerc.core.SharedLogger
import ca.sebleclerc.localization.LocalizedStrings
import ca.sebleclerc.localization.StringsService
import ca.sebleclerc.localization.models.Langue
import com.russhwolf.settings.Settings
import com.russhwolf.settings.StorageSettings
import com.russhwolf.settings.get
import com.russhwolf.settings.set
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.asStateFlow

interface Localizable {
  val locale: StateFlow<Langue>

  fun getLocalizedStrings(locale: Langue): LocalizedStrings
  fun updateLocale(newLocale: Langue)
}

class LocalizationService : Localizable {
  private val settingsKey = "L18N-Service-Locale"
  private val settings: Settings = StorageSettings()
  private val stringsService = StringsService()
  private val mutableLocale = MutableStateFlow(Langue.FRENCH)
  override val locale = mutableLocale.asStateFlow()

  init {
    val savedLocaleString = settings[settingsKey, "FR"]
    val savedLocale = if (savedLocaleString == "FR") Langue.FRENCH else Langue.ENGLISH
    mutableLocale.value = savedLocale
  }

  override fun getLocalizedStrings(locale: Langue): LocalizedStrings {
    return stringsService.getLocalizedStrings(locale)
  }

  override fun updateLocale(newLocale: Langue) {
    SharedLogger.debug("Applying new Locale to ${newLocale.displayTitle}")
    settings.set(settingsKey, newLocale.id)
    mutableLocale.value = newLocale
  }
}
