package ca.sebleclerc.localization.strings.ui

//region DatabaseInfoStrings interface

interface DatabaseInfoStrings {
  val currentHeader: String

  val progressTitle: String
  val progressDetail: String

  val rebuildButton: String
  val rebuildConfirmMessage: String
  val rebuildConfirmTitle: String
  val rebuildContactMessage: String
  val rebuildHeader: String
  val rebuildMessage: String

  val pinsHeader: String

  val title: String

  val checkForUpdate: String

  fun updateButton(version: String): String
  val updateHeader: String
  val updateDoneMessage: String
  val updateErrorMessage: String
  val updateNoUpdate: String
}

//endregion

class EnglishDatabaseInfoStrings : DatabaseInfoStrings {
  override val currentHeader = "Current Version"

  override val progressTitle = "Please Wait"
  override val progressDetail = "Updating data..."

  override val rebuildButton = "Rebuild"
  override val rebuildConfirmMessage =
    "Are you sure you want to rebuild local data? It might take a few seconds."
  override val rebuildConfirmTitle = "Rebuild?"
  override val rebuildContactMessage = "If that doesn't fix your problem, please contact me."
  override val rebuildHeader = "Rebuild"
  override val rebuildMessage = "Having problem with the app? Try rebuild it's data."

  override val pinsHeader = "Places"

  override val title = "Data Info"

  override val checkForUpdate: String = "Check for updates"

  override fun updateButton(version: String): String {
    return "Upgrade to $version"
  }

  override val updateHeader = "Update"
  override val updateDoneMessage = "Update done"
  override val updateErrorMessage = "Error. Try again."
  override val updateNoUpdate = "No update available"
}

class FrenchDatabaseInfoStrings : DatabaseInfoStrings {
  override val currentHeader = "Version courante"

  override val progressTitle = "Veuillez attendre"
  override val progressDetail = "Mise à jour des données..."

  override val rebuildButton = "Rebâtir"
  override val rebuildConfirmMessage =
    "Êtes-vous sûr de vouloir rebâtir les données? Cela peut prendre quelques secondes."
  override val rebuildConfirmTitle = "Rebâtir?"
  override val rebuildContactMessage =
    "Si cela ne corrige pas votre problème, veuillez me contacter."
  override val rebuildHeader = "Rebâtir"
  override val rebuildMessage = "Un problème avec l'app? Essaie de rebâtir les données."

  override val pinsHeader = "Endroits"

  override val title = "Info des données"

  override val checkForUpdate: String = "Vérifier mise à jour"

  override fun updateButton(version: String): String {
    return "Mise à jour $version"
  }

  override val updateHeader = "Mise à jour"
  override val updateDoneMessage = "Mise à jour effectuée"
  override val updateErrorMessage = "Erreur. Veuillez réessayer."
  override val updateNoUpdate = "Aucune mise à jour disponible"
}
