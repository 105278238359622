package ca.sebleclerc.localization.strings.model

import ca.sebleclerc.core.SharedLogger
import ca.sebleclerc.core.models.Day

interface DayStrings {
  val january: String
  val february: String
  val march: String
  val april: String
  val may: String
  val june: String
  val july: String
  val august: String
  val september: String
  val october: String
  val november: String
  val december: String

  fun localizedMonthDay(day: Day): String
}

class EnglishDayStrings : DayStrings {
  override val january = "January"
  override val february = "February"
  override val march = "March"
  override val april = "April"
  override val may = "May"
  override val june = "June"
  override val july = "July"
  override val august = "August"
  override val september = "September"
  override val october = "October"
  override val november = "November"
  override val december = "December"

  override fun localizedMonthDay(day: Day): String {
    return "${DayToLocalizedString.month(day.month, this)} ${day.day}"
  }
}

class FrenchDayStrings : DayStrings {
  override val january = "janvier"
  override val february = "février"
  override val march = "mars"
  override val april = "avril"
  override val may = "mai"
  override val june = "juin"
  override val july = "juillet"
  override val august = "août"
  override val september = "septembre"
  override val october = "octobre"
  override val november = "novembre"
  override val december = "décembre"

  override fun localizedMonthDay(day: Day): String {
    return "${day.day} ${DayToLocalizedString.month(day.month, this)}"
  }
}

private object DayToLocalizedString {
  fun month(month: Int, strings: DayStrings): String {
    when (month) {
      1 -> return strings.january
      2 -> return strings.february
      3 -> return strings.march
      4 -> return strings.april
      5 -> return strings.may
      6 -> return strings.june
      7 -> return strings.july
      8 -> return strings.august
      9 -> return strings.september
      10 -> return strings.october
      11 -> return strings.november
      12 -> return strings.december
      else -> {
        SharedLogger.error("Unknown month value $month")
        return ""
      }
    }
  }
}
