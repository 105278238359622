package ca.sebleclerc.localization.strings.ui.site

interface HomeStrings {
  val hero: HeroStrings
}

object FrHomeStrings : HomeStrings {
  override val hero = FrHeroStrings
}

object EnHomeStrings : HomeStrings {
  override val hero = EnHeroStrings
}

interface HeroStrings {
  val title: String
  val subtitle: String

  val featuresButton: String
}

object FrHeroStrings : HeroStrings {
  override val title: String
    get() = "L'app qui te permet de trouver de la bonne bière!"

  override val subtitle: String
    get() = "Elle vous permet de trouver les établissements brassicoles québécois les plus proches de votre position actuelle." // ktlint-disable max-line-length

  override val featuresButton: String
    get() = "Features"
}

object EnHeroStrings : HeroStrings {
  override val title: String
    get() = "The app to find good beer!"

  override val subtitle: String
    get() = "It help you find Quebec brewing places near you."

  override val featuresButton: String
    get() = "Features"
}
