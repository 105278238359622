package ca.sebleclerc.localization.strings.ui

interface AdminPanelStrings {
  val showTravelToastDisabled: String
  val showTravelToastEnabled: String
}

class EnglishAdminPanelStrings : AdminPanelStrings {
  override val showTravelToastDisabled = "Travel places disabled."
  override val showTravelToastEnabled = "Travel places enabled."
}

class FrenchAdminPanelStrings : AdminPanelStrings {
  override val showTravelToastDisabled = "Mode voyage désactivé."
  override val showTravelToastEnabled = "Mode voyage activé."
}
