package ca.sebleclerc.localization.strings.ui

import ca.sebleclerc.core.models.EventEditionYear
import ca.sebleclerc.localization.strings.model.DayStrings

interface EventDetailsStrings {
  val whatSectionTitle: String
  val whenSectionTitle: String
  val whereSectionTitle: String
  val contactsSectionTitle: String

  val openMapTitle: String
  val appleMap: String
  val googleMap: String

  fun singleDayWhenText(event: EventEditionYear): String
  fun fromToWhenText(event: EventEditionYear): String
  fun fromWhenText(event: EventEditionYear): String
  fun toWhenText(event: EventEditionYear): String
}

class EnglishEventDetailsStrings(val dayStrings: DayStrings) : EventDetailsStrings {
  override val whatSectionTitle = "What?"
  override val whenSectionTitle = "When?"
  override val whereSectionTitle = "Where?"
  override val contactsSectionTitle = "Contacts"
  override val openMapTitle = "Navigate To Address?"
  override val appleMap = "Plan"
  override val googleMap = "Google Maps"

  override fun singleDayWhenText(event: EventEditionYear): String {
    return "On ${dayStrings.localizedMonthDay(event.startDate)}"
  }

  override fun fromToWhenText(event: EventEditionYear): String {
    val startDate = dayStrings.localizedMonthDay(event.startDate)
    val endDate = dayStrings.localizedMonthDay(event.endDate)

    return "From $startDate to $endDate"
  }

  override fun fromWhenText(event: EventEditionYear): String {
    return "From ${dayStrings.localizedMonthDay(event.startDate)}"
  }

  override fun toWhenText(event: EventEditionYear): String {
    return "To ${dayStrings.localizedMonthDay(event.endDate)}"
  }
}

class FrenchEventDetailsStrings(val dayStrings: DayStrings) : EventDetailsStrings {
  override val whatSectionTitle = "Quoi?"
  override val whenSectionTitle = "Quand?"
  override val whereSectionTitle = "Où?"
  override val contactsSectionTitle = "Contacts"
  override val openMapTitle = "Naviguer vers l'adresse?"
  override val appleMap = "Plan"
  override val googleMap = "Google Maps"

  override fun singleDayWhenText(event: EventEditionYear): String {
    return "Le ${dayStrings.localizedMonthDay(event.startDate)}"
  }

  override fun fromToWhenText(event: EventEditionYear): String {
    val startDate = dayStrings.localizedMonthDay(event.startDate)
    val endDate = dayStrings.localizedMonthDay(event.endDate)

    return "Du $startDate au $endDate"
  }

  override fun fromWhenText(event: EventEditionYear): String {
    return "À partir du ${dayStrings.localizedMonthDay(event.startDate)}"
  }

  override fun toWhenText(event: EventEditionYear): String {
    return "jusqu'au ${dayStrings.localizedMonthDay(event.endDate)}"
  }
}
